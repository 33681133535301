import React from "react";
import "./ImagesCollage.scss";
import useWindowDimensions from "@hooks/useWindowDimensions";
import { MOBILE_MAX_WIDTH } from "@constants";
import Image from "@common/Image/Image";
import CareersTeamDesk1x from "@images/careers/careers-team-desk-1x.jpg";
import CareersTeamDesk2x from "@images/careers/careers-team-desk-2x.jpg";
import CareersTeamDesk3x from "@images/careers/careers-team-desk-3x.jpg";
import team2careers1x from "@images/careers/team2careers1x.jpg";
import team2careers2x from "@images/careers/team2careers2x.jpg";
import team2careers3x from "@images/careers/team2careers3x.jpg";
import team3careers1x from "@images/careers/team3careers1x.jpg";
import team3careers2x from "@images/careers/team3careers2x.jpg";
import team3careers3x from "@images/careers/team3careers3x.jpg";
import team4careers1x from "@images/careers/team4careers1x.jpg";
import team4careers2x from "@images/careers/team4careers2x.jpg";
import team4careers3x from "@images/careers/team4careers3x.jpg";
import team5careers1x from "@images/careers/team5careers1x.jpg";
import team5careers2x from "@images/careers/team5careers2x.jpg";
import team5careers3x from "@images/careers/team5careers3x.jpg";
import team6careers1x from "@images/careers/team6careers1x.jpg";
import team6careers2x from "@images/careers/team6careers2x.jpg";
import team6careers3x from "@images/careers/team6careers3x.jpg";
import team7careers1x from "@images/careers/team7careers1x.jpg";
import team7careers2x from "@images/careers/team7careers2x.jpg";
import team7careers3x from "@images/careers/team7careers3x.jpg";
import team8careers1x from "@images/careers/team8careers1x.jpg";
import team8careers2x from "@images/careers/team8careers2x.jpg";
import team8careers3x from "@images/careers/team8careers3x.jpg";
import team9careers1x from "@images/careers/team9careers1x.jpg";
import team9careers2x from "@images/careers/team9careers2x.jpg";
import team9careers3x from "@images/careers/team9careers3x.jpg";

const ImagesCollage = () => {
    const { width } = useWindowDimensions();
    const isMobile = width <= MOBILE_MAX_WIDTH;

    return (
        <div>
            {isMobile ? (
                <div className="careers-images-collage">
                    <div className="careers-images-collage__container">
                        <div className="careers-images-collage__image-container">
                            <Image
                                src={CareersTeamDesk1x}
                                srcSet={`${CareersTeamDesk2x} 2x, ${CareersTeamDesk3x} 3x`}
                                className="careers-images-collage__image"
                                alt="impressit brilliant specialists"
                                isLazy={false}
                            />
                        </div>
                        <div className="careers-images-collage__image-container">
                            <Image
                                src={team2careers1x}
                                srcSet={`${team2careers2x} 2x, ${team2careers3x} 3x`}
                                className="careers-images-collage__image"
                                alt="Impressit company day celebration"
                                isLazy={false}
                            />
                        </div>
                        <div className="careers-images-collage__image-container">
                            <Image
                                src={team3careers1x}
                                srcSet={`${team3careers2x} 2x, ${team3careers3x} 3x`}
                                className="careers-images-collage__image"
                                alt="Our office life pulse"
                                isLazy={false}
                            />
                        </div>
                        <div className="careers-images-collage__image-container">
                            <Image
                                src={team4careers1x}
                                srcSet={`${team4careers2x} 2x, ${team4careers3x} 3x`}
                                className="careers-images-collage__image"
                                alt="Office kicker winners"
                                isLazy={false}
                            />
                        </div>
                        <div className="careers-images-collage__image-container">
                            <Image
                                src={team5careers1x}
                                srcSet={`${team5careers2x} 2x, ${team5careers3x} 3x`}
                                className="careers-images-collage__image"
                                alt="Dan Henyk and Roman Zomko"
                                isLazy={false}
                            />
                        </div>
                        <div className="careers-images-collage__image-container">
                            <Image
                                src={team6careers1x}
                                srcSet={`${team6careers2x} 2x, ${team6careers3x} 3x`}
                                className="careers-images-collage__image"
                                alt="New employees are opening Welcome pack"
                                isLazy={false}
                            />
                        </div>
                        <div className="careers-images-collage__image-container">
                            <Image
                                src={team7careers1x}
                                srcSet={`${team7careers2x} 2x, ${team7careers3x} 3x`}
                                className="careers-images-collage__image"
                                alt="Andrew Lekh and Dima Tkach"
                                isLazy={false}
                            />
                        </div>
                        <div className="careers-images-collage__image-container">
                            <Image
                                src={team8careers1x}
                                srcSet={`${team8careers2x} 2x, ${team8careers3x} 3x`}
                                className="careers-images-collage__image"
                                alt="Anastasia Dosiak"
                                isLazy={false}
                            />
                        </div>
                        <div className="careers-images-collage__image-container">
                            <Image
                                src={team9careers1x}
                                srcSet={`${team9careers2x} 2x, ${team9careers3x} 3x`}
                                className="careers-images-collage__image"
                                alt="Our office kicker championship"
                                isLazy={false}
                            />
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    <div className="careers-images-collage">
                        <div className="careers-images-collage__column-left">
                            <div className="careers-images-collage__image-container">
                                <Image
                                    src={CareersTeamDesk1x}
                                    srcSet={`${CareersTeamDesk2x} 2x, ${CareersTeamDesk3x} 3x`}
                                    className="careers-images-collage__image"
                                    alt="impressit brilliant specialists"
                                    isLazy={false}
                                />
                            </div>
                            <div className="careers-images-collage__image-container">
                                <Image
                                    src={team2careers1x}
                                    srcSet={`${team2careers2x} 2x, ${team2careers3x} 3x`}
                                    className="careers-images-collage__image"
                                    alt="Impressit company day celebration"
                                    isLazy={false}
                                />
                            </div>
                        </div>
                        <div className="careers-images-collage__column-right-above">
                            <div className="careers-images-collage__image-container">
                                <Image
                                    src={team3careers1x}
                                    srcSet={`${team3careers2x} 2x, ${team3careers3x} 3x`}
                                    className="careers-images-collage__image"
                                    alt="Our office life pulse"
                                    isLazy={false}
                                />
                            </div>
                            <div className="careers-images-collage__image-container">
                                <Image
                                    src={team4careers1x}
                                    srcSet={`${team4careers2x} 2x, ${team4careers3x} 3x`}
                                    className="careers-images-collage__image"
                                    alt="Office kicker winners"
                                    isLazy={false}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="careers-images-collage">
                        <div className="careers-images-collage__column-left">
                            <div className="careers-images-collage__image-container">
                                <Image
                                    src={team5careers1x}
                                    srcSet={`${team5careers2x} 2x, ${team5careers3x} 3x`}
                                    className="careers-images-collage__image"
                                    alt="Dan Henyk and Roman Zomko"
                                />
                            </div>
                            <div className="careers-images-collage__image-container">
                                <Image
                                    src={team6careers1x}
                                    srcSet={`${team6careers2x} 2x, ${team6careers3x} 3x`}
                                    className="careers-images-collage__image"
                                    alt="New employees are opening Welcome pack"
                                />
                            </div>
                        </div>
                        <div className="careers-images-collage__column-right-below">
                            <div className="careers-images-collage__image-container">
                                <Image
                                    src={team7careers1x}
                                    srcSet={`${team7careers2x} 2x, ${team7careers3x} 3x`}
                                    className="careers-images-collage__image"
                                    alt="Andrew Lekh and Dima Tkach"
                                />
                            </div>
                            <div className="careers-images-collage__column-right-below-bottom">
                                <div className="careers-images-collage__image-container">
                                    <Image
                                        src={team8careers1x}
                                        srcSet={`${team8careers2x} 2x, ${team8careers3x} 3x`}
                                        className="careers-images-collage__image"
                                        alt="Anastasia Dosiak"
                                    />
                                </div>
                                <div className="careers-images-collage__image-container">
                                    <Image
                                        src={team9careers1x}
                                        srcSet={`${team9careers2x} 2x, ${team9careers3x} 3x`}
                                        className="careers-images-collage__image"
                                        alt="Our office kicker championship"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ImagesCollage;
